import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, DialogContentText, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import FileUploadWidget from 'components/FileUploadWidget';
import { FileUploadResult } from 'models/common/fileUploadResult';
import agent from 'api/agent';
import { ITermsAndConditions } from 'models/businessEvent/termsAndConditions';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import SimpleCard from 'components/SimpleCard';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const TNCEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedBusinessEvent } = useContext(RootStoreContext).businessEventStore;
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [termsAndConditions, setTermsAndConditions] = useState(selectedBusinessEvent.termsAndConditions);
    const [fileToUpload, setFileToUpload] = useState<FileUploadResult>(null);

    const buttons = [
        {
            onButtonAction: () => {
                window.open(termsAndConditions?.url);
            },
            buttonText: 'Megtekintés',
            color: 'primary',
        },
    ];

    return (
        <>
            <Formik
                initialValues={{
                    id: selectedBusinessEvent.id,
                    termsAndConditions: termsAndConditions,
                }}
                validationSchema={Yup.object().shape({})}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    const newTermsAndConditions: ITermsAndConditions = {
                        title: fileToUpload.fileName,
                        url: fileToUpload.url,
                    };

                    setTermsAndConditions(newTermsAndConditions);

                    agent.BusinessEvent.updateAttachmentById({
                        id: selectedBusinessEvent.id,
                        termsAndConditions: newTermsAndConditions,
                    })
                        .then(() => {
                            showSuccessToast(`Az új fájl elmentve!`);
                        })
                        .catch(() => showErrorToast(`A fájl frissítése sikertelen!`))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <>
                        {isSubmitting ? (
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                <CircularProgress />
                                <Typography component="div">
                                    <Box textAlign="center" m={1}>
                                        Betöltés...
                                    </Box>
                                </Typography>
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit} noValidate>
                                <Box mt={2}>
                                    <DialogContentText>Már feltöltött fájl:</DialogContentText>
                                    {termsAndConditions ? (
                                        <>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} sm={2} key={termsAndConditions.url}>
                                                    <SimpleCard
                                                        url={termsAndConditions?.url}
                                                        buttons={buttons}
                                                        title={termsAndConditions?.title}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Box mt={2}>
                                            <DialogContentText style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                                Nincs még feltöltötve fájl
                                            </DialogContentText>
                                        </Box>
                                    )}
                                </Box>
                                <Box mt={2}>
                                    {isLoading ? (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ height: '100%' }}
                                        >
                                            <CircularProgress color="inherit" />
                                            {loadingMessage && (
                                                <Typography component="div">
                                                    <Box textAlign="center" m={1}>
                                                        {loadingMessage}
                                                    </Box>
                                                </Typography>
                                            )}
                                        </Box>
                                    ) : (
                                        <div>
                                            {
                                                <Box mt={2}>
                                                    <DialogContentText>Fájl feltöltése:</DialogContentText>
                                                    <FileUploadWidget
                                                        setFileUploadResult={setFileToUpload}
                                                        setIsLoading={setIsLoading}
                                                        setLoadingMessage={setLoadingMessage}
                                                        fileUploadResult={fileToUpload}
                                                    />
                                                </Box>
                                            }
                                        </div>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || isLoading}
                                        type="submit"
                                    >
                                        Mentés
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </>
                )}
            </Formik>
        </>
    );
};

export default observer(TNCEditor);
