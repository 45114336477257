import React, { useContext } from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { RootStoreContext } from 'mobx/rootStore';

const Header: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const { selectedBusinessEvent } = useContext(RootStoreContext).businessEventStore;

    const getBusinessEventName = () => {
        if (isLoading || !selectedBusinessEvent) {
            return '';
        }

        return ` - ${selectedBusinessEvent.name}`;
    };

    return (
        <Grid container spacing={3}>
            <Grid item>
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    <Link to="/">
                        <span style={{ color: 'black' }}>Főoldal</span>
                    </Link>
                    <Link to="/">
                        <span style={{ color: 'black' }}>Esemény kezelése</span>
                    </Link>
                    <Typography variant="body1" color="textPrimary">
                        {'Esemény szerkesztés'}
                    </Typography>
                </Breadcrumbs>
                <Typography variant="h3" color="textPrimary">
                    {`Esemény szerkesztés${getBusinessEventName()}`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
