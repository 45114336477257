import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, IconButton, InputAdornment, Stack, SvgIcon, TextField } from '@mui/material';
import { Add, Folder, Search } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomPage from 'components/CustomPage';
import agent from 'api/agent';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CustomListLoader from 'components/CustomListLoader';
import Header from './components/Header';
import CreateNewReferenceDialog from './components/CreateReference/CreateNewReferenceDialog';

const ReferencesManager: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        loading: true,
        rows: [],
        totalRows: 0,
        rowCount: 0,
        pageSize: 10,
        page: 0,
    });

    const [isReloadRequested, setReloadRequest] = useState<boolean>(false);
    const [referenceName, setReferenceName] = useState(null);
    const [isCreateModeEnabled, setIsCreateModeEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (!data.loading) {
            updateData('loading', true);
        }
        (async () => {
            const referencePage = await agent.Reference.getFilteredPage(data.page, data.pageSize, referenceName);
            updateData('rowCount', referencePage.allCount);
            setTimeout(() => {
                updateData('rows', referencePage.page);
                updateData('loading', false);
            }, 100);
        })();
    }, [data.page, data.pageSize, isReloadRequested]);

    const search = () => {
        updateData('page', 0);
        setReloadRequest(!isReloadRequested);
    };

    const openReferenceForEdit = (id: string) => {
        navigate({
            pathname: 'referenciaszerkesztes',
            search: createSearchParams({ id }).toString(),
        });
    };

    const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Referenciák',
            sortable: true,
            flex: 1,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'left',
                            cursor: 'pointer',
                            width: '100%',
                            height: '100%',
                        }}
                        onClick={() => openReferenceForEdit(params.id as string)}
                    >
                        <IconButton component="span" style={{ height: 100, width: 100 }}>
                            <Folder style={{ height: 50, width: 50 }} />
                        </IconButton>
                        <span style={{ fontSize: '17px' }}>{params.row.name}</span>
                    </div>
                );
            },
        },
    ];

    return (
        <CustomPage title="Referenciák kezelése | Events & Awards - Admin">
            <Container maxWidth={false}>
                <Header />
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Box display="flex" alignItems="center" mb={2} sx={{ flexWrap: 'wrap' }}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    fontSize="small"
                                                    color="action"
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'block',
                                                            md: 'block',
                                                            lg: 'block',
                                                            xl: 'block',
                                                        },
                                                    }}
                                                >
                                                    <Search />
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setReferenceName(e.target.value)}
                                    label="Referencia neve"
                                    value={referenceName}
                                />

                                <Box m={1}></Box>
                                <Box m={1}>
                                    <Button variant="contained" color="secondary" onClick={search}>
                                        Keresés
                                    </Button>
                                </Box>
                                <Box flexGrow={1} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsCreateModeEnabled(true)}
                                >
                                    <Add />
                                    Referencia hozzáadása
                                </Button>
                            </Box>
                            <PerfectScrollbar>
                                <DataGrid
                                    components={{
                                        LoadingOverlay: CustomListLoader,
                                        NoRowsOverlay: () => (
                                            <Stack mt={20} alignItems="center">
                                                Nem található a keresésnek megfelelő elem!
                                            </Stack>
                                        ),
                                    }}
                                    componentsProps={{
                                        pagination: {
                                            labelRowsPerPage: 'Oldalankénti sorok száma:',
                                        },
                                    }}
                                    pagination
                                    autoHeight
                                    paginationMode="server"
                                    loading={data.loading}
                                    rowCount={data.rowCount}
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    onPageSizeChange={(newPageSize) => {
                                        updateData('pageSize', newPageSize);
                                    }}
                                    page={data.page}
                                    pageSize={data.pageSize}
                                    rows={data.rows}
                                    columns={columns}
                                    onPageChange={(pageNumber) => {
                                        updateData('page', pageNumber);
                                    }}
                                    rowHeight={100}
                                    disableColumnMenu
                                    hideFooterSelectedRowCount={true}
                                />
                            </PerfectScrollbar>
                        </Box>
                        <CreateNewReferenceDialog
                            setIsCreateModeEnabled={setIsCreateModeEnabled}
                            setReloadRequest={setReloadRequest}
                            isCreateModeEnabled={isCreateModeEnabled}
                        />
                    </Card>
                </Box>
            </Container>
        </CustomPage>
    );
};

export default ReferencesManager;
