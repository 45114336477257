import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    iconSecondary: {
        marginTop: 8,
        fontSize: 30,
        marginRight: 10,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionRoot: {
        width: '100%',
    },
}));

const EditorElement: React.FC<{ statusIcon: any; content: any; sectionTitle: any }> = ({
    statusIcon,
    content,
    sectionTitle,
}) => {
    const styles = useStyles();
    const StatusIcon = statusIcon;

    return (
        <Box display="flex" mt={1}>
            <StatusIcon
                className={styles.iconSecondary}
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'block',
                        md: 'block',
                        lg: 'block',
                        xl: 'block',
                    },
                }}
            />
            <Accordion className={styles.accordionRoot}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={styles.heading}>{sectionTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={styles.accordionRoot}>{content}</Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default EditorElement;
