import { CircularProgress, Box, Typography, DialogContentText, Grid } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import React from 'react';

const ExistingImage: React.FC<{
    isLoading?: boolean;
    loadingMessage?: string;
    existingImage: string;
}> = ({ isLoading, loadingMessage, existingImage }) => {
    return (
        <Box mt={2}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {loadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {loadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    <DialogContentText>Már feltöltött kép:</DialogContentText>
                    {existingImage ? (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} key={existingImage}>
                                    <SimpleImageCard imageUrl={existingImage} buttons={[]} />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box mt={2}>
                            <DialogContentText style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                Nincs még feltöltött kép
                            </DialogContentText>
                        </Box>
                    )}
                </div>
            )}
        </Box>
    );
};
export default ExistingImage;
