import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, IconButton, InputAdornment, Stack, SvgIcon, TextField } from '@mui/material';
import { Add, Check, EditOutlined, RemoveCircle, Search, Poll, NoAccounts } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomPage from 'components/CustomPage';
import { showSuccessToast } from 'utils/customToaster';
import agent from 'api/agent';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CustomListLoader from 'components/CustomListLoader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Header from './components/Header';
import CreateNewBusinessEventDialog from './components/CreateNewBusinessEventDialog';
import { format } from 'date-fns';

const BusinessEventsManager: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        loading: true,
        rows: [],
        totalRows: 0,
        rowCount: 0,
        pageSize: 10,
        page: 0,
    });

    const [businessEventName, setBusinessEventName] = useState('');
    const [isReloadRequested, setReloadRequest] = useState<boolean>(false);
    const [filterFromDate, setFilterFromDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const initialDate = dayjs(new Date()).add(1, 'month');
    const [filterToDate, setFilterToDate] = React.useState<Dayjs | null>(dayjs(initialDate));
    const [isCreateModeEnabled, setIsCreateModeEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (!data.loading) {
            updateData('loading', true);
        }
        (async () => {
            const businessEventPage = await agent.BusinessEvent.getFilteredPage(
                data.page,
                data.pageSize,
                filterFromDate.toDate(),
                filterToDate.toDate(),
                businessEventName,
            );
            updateData('rowCount', businessEventPage.allCount);

            setTimeout(() => {
                updateData('rows', businessEventPage.page);
                updateData('loading', false);
            }, 100);
        })();
    }, [data.page, data.pageSize, isReloadRequested]);

    const search = () => {
        updateData('page', 0);
        setReloadRequest(!isReloadRequested);
    };

    const openBusinessEventForEdit = (id: string) => {
        navigate({
            pathname: 'esemenyszerkesztes',
            search: createSearchParams({ id }).toString(),
        });
    };

    const setNomineeActivation = (businessEventId: string, isEnabled: boolean) => {
        updateData('loading', true);

        const request = isEnabled
            ? agent.BusinessEvent.enableNomineesById(businessEventId)
            : agent.BusinessEvent.disableNomineesById(businessEventId);

        request
            .then(() => {
                showSuccessToast(`Jelöltek ${!isEnabled ? 'letiltása' : 'engedélyezése'} sikeres!`);
            })
            .finally(() => {
                setReloadRequest(!isReloadRequested);
            });
    };

    const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

    const columns: GridColDef[] = [
        {
            field: 'edit',
            headerName: 'Szerkesztés',
            sortable: false,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <IconButton component="span" onClick={() => openBusinessEventForEdit(params.id as string)}>
                        <EditOutlined />
                    </IconButton>
                );
            },
        },
        {
            field: 'areNomineesEnabled',
            headerName: 'Jelöltek engedélyezése',
            sortable: true,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                return (
                    <IconButton
                        component="span"
                        onClick={() =>
                            setNomineeActivation(params.id as string, !params.getValue(params.id, 'areNomineesEnabled'))
                        }
                    >
                        {params.getValue(params.id, 'areNomineesEnabled') ? (
                            <Check style={{ color: 'green' }} />
                        ) : (
                            <RemoveCircle style={{ color: 'red' }} />
                        )}
                    </IconButton>
                );
            },
        },
        {
            field: 'isVotingEnabled',
            headerName: 'Jelleg',
            sortable: true,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                return (
                    <IconButton component="span">
                        {params.getValue(params.id, 'isVotingEnabled') ? (
                            <Poll style={{ color: 'green' }} />
                        ) : (
                            <NoAccounts style={{ color: 'red' }} />
                        )}
                    </IconButton>
                );
            },
        },
        {
            field: 'name',
            headerName: 'Név',
            sortable: true,
            flex: 2,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'location',
            headerName: 'Helyszín',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'startDate',
            headerName: 'Kezdés',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridCellParams) => (
                <span>{format(new Date(params.getValue(params.id, 'startDate').toString()), 'yyyy-MM-dd HH:mm')}</span>
            ),
        },
        {
            field: 'endDate',
            headerName: 'Zárás',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridCellParams) => (
                <span>{format(new Date(params.getValue(params.id, 'endDate').toString()), 'yyyy-MM-dd HH:mm')}</span>
            ),
        },
        {
            field: 'location',
            headerName: 'Helyszín',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'attendeeCount',
            headerName: 'Jelentkezők száma',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridCellParams) => <span>{params.getValue(params.id, 'attendeeCount') || '0'}</span>,
        },
        {
            field: 'nomineeCount',
            headerName: 'Jelöltek száma',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridCellParams) => <span>{params.getValue(params.id, 'nomineeCount') || '0'}</span>,
        },
        {
            field: 'isTermsAndConditionsAttached',
            headerName: 'Csatolmány',
            sortable: true,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        {params.getValue(params.id, 'isTermsAndConditionsAttached') ? (
                            <IconButton
                                component="span"
                                onClick={() => {
                                    const param = data.rows.find((x) => x.id == (params.id as string));
                                    window.open(param?.attachmentLink);
                                }}
                            >
                                <Check style={{ color: 'green' }} />
                            </IconButton>
                        ) : (
                            <RemoveCircle style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomPage title="Események kezelése | Events & Awards - Admin">
                <Container maxWidth={false}>
                    <Header />
                    <Box mt={2}>
                        <Card>
                            <Box p={2}>
                                <Box display="flex" alignItems="center" mb={2} sx={{ flexWrap: 'wrap' }}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        fontSize="small"
                                                        color="action"
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'block',
                                                                md: 'block',
                                                                lg: 'block',
                                                                xl: 'block',
                                                            },
                                                        }}
                                                    >
                                                        <Search />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => setBusinessEventName(e.target.value)}
                                        label="Esemény neve"
                                        value={businessEventName}
                                    />

                                    <Box m={1}></Box>
                                    <DatePicker
                                        label="Dátum -tól"
                                        onChange={(newValue) => setFilterFromDate(newValue)}
                                        format="YYYY.MM.DD."
                                        value={filterFromDate}
                                    />
                                    <Box m={1}></Box>
                                    <DatePicker
                                        label="Dátum -ig"
                                        onChange={(newValue) => setFilterToDate(newValue)}
                                        format="YYYY.MM.DD."
                                        value={filterToDate}
                                    />
                                    <Box m={1}></Box>
                                    <Box m={1}>
                                        <Button variant="contained" color="secondary" onClick={search}>
                                            Keresés
                                        </Button>
                                    </Box>
                                    <Box flexGrow={1} />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setIsCreateModeEnabled(true)}
                                    >
                                        <Add />
                                        Esemény hozzáadása
                                    </Button>
                                </Box>
                                <PerfectScrollbar>
                                    <DataGrid
                                        components={{
                                            LoadingOverlay: CustomListLoader,
                                            NoRowsOverlay: () => (
                                                <Stack mt={20} alignItems="center">
                                                    Nem található a keresésnek megfelelő elem!
                                                </Stack>
                                            ),
                                        }}
                                        componentsProps={{
                                            pagination: {
                                                labelRowsPerPage: 'Oldalankénti sorok száma:',
                                            },
                                        }}
                                        pagination
                                        autoHeight
                                        paginationMode="server"
                                        loading={data.loading}
                                        rowCount={data.rowCount}
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        onPageSizeChange={(newPageSize) => {
                                            updateData('pageSize', newPageSize);
                                        }}
                                        page={data.page}
                                        pageSize={data.pageSize}
                                        rows={data.rows}
                                        columns={columns}
                                        onPageChange={(pageNumber) => {
                                            updateData('page', pageNumber);
                                        }}
                                        disableColumnMenu
                                        hideFooterSelectedRowCount={true}
                                    />
                                </PerfectScrollbar>
                            </Box>
                            <CreateNewBusinessEventDialog
                                setIsCreateModeEnabled={setIsCreateModeEnabled}
                                setReloadRequest={setReloadRequest}
                                isCreateModeEnabled={isCreateModeEnabled}
                            />
                        </Card>
                    </Box>
                </Container>
            </CustomPage>
        </LocalizationProvider>
    );
};

export default BusinessEventsManager;
