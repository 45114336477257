import React, { useState } from 'react';
import { CircularProgress, DialogContentText, Typography, Box, Grid } from '@mui/material';
import ImageUploadWidget from 'components/ImageUploadWidget';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import SimpleImageCard from 'components/SimpleImageCard';
import agent from 'api/agent';

const CreateNewCoverImage: React.FC<{
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    coverImageToUpload: ImageUploadResult;
    setCoverImageToUpload: (imageUploadResult: ImageUploadResult) => void;
}> = ({ isLoading, setIsLoading, coverImageToUpload, setCoverImageToUpload }) => {
    const [loadingMessage, setLoadingMessage] = useState<string>();

    const deleteImage = (coverImageUrl: string) => {
        agent.Image.deleteImage(coverImageUrl)
            .then(() => {
                setCoverImageToUpload(null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Box mt={2}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {loadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {loadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <>
                    {coverImageToUpload ? (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <DialogContentText>Borítókép:</DialogContentText>
                                <SimpleImageCard
                                    imageUrl={coverImageToUpload.imageUrl}
                                    buttons={[
                                        {
                                            onButtonAction: () => {
                                                setLoadingMessage('Sponsor törlése...');
                                                setIsLoading(true);
                                                deleteImage(coverImageToUpload.imageUrl);
                                            },
                                            buttonText: 'Törlés',
                                            color: 'error',
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <DialogContentText>Borítókép feltöltése:</DialogContentText>
                            <ImageUploadWidget
                                setImageUrlToUpload={setCoverImageToUpload}
                                setIsLoading={setIsLoading}
                                setLoadingMessage={setLoadingMessage}
                            />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default CreateNewCoverImage;
