import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import agent from 'api/agent';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { IUpdateReferenceThumbnailImage } from 'models/reference/updateThumbnailImage';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import CreateNewImageGrid from 'components/Common/SingleImageComponents/CreateNewImageGrid';
import ExistingImage from 'components/Common/SingleImageComponents/ExistingImage';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const ImageEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedReference } = useContext(RootStoreContext).referenceStore;
    const existingImage = selectedReference.thumbnailImage.toString();
    const [imageToUpload, setImageToUpload] = useState<ImageUploadResult>(null);
    const [isUploadImageLoading, setIsUploadImageLoading] = useState(false);

    return (
        <Formik
            initialValues={{
                id: selectedReference.id,
                imageUrl: imageToUpload?.imageUrl,
            }}
            validationSchema={Yup.object().shape({})}
            enableReinitialize
            onSubmit={(_, { setSubmitting }) => {
                if (imageToUpload == null) {
                    setSubmitting(false);
                    showErrorToast(`Kérem adjon meg egy képet!`);
                    return;
                }
                const imageInformation: IUpdateReferenceThumbnailImage = {
                    id: selectedReference.id,
                    thumbnailImage: imageToUpload.imageUrl,
                };
                agent.Reference.updateThumbnailImage(imageInformation)
                    .then(() => {
                        showSuccessToast(`A módosítások elmentve!`);
                        window.location.reload();
                    })
                    .catch(() => showErrorToast(`Mentés sikertelen!`))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <>
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                            <Typography component="div">
                                <Box textAlign="center" m={1}>
                                    Betöltés...
                                </Box>
                            </Typography>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit} noValidate>
                            <ExistingImage existingImage={existingImage} />
                            <Box mt={2}>
                                <CreateNewImageGrid
                                    thumbnailImageToUpload={imageToUpload}
                                    setThumbnailImageToUpload={setImageToUpload}
                                    isUploadImageLoading={isUploadImageLoading}
                                    setIsUploadImageLoading={setIsUploadImageLoading}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || isUploadImageLoading}
                                    type="submit"
                                >
                                    Mentés
                                </Button>
                            </Box>
                        </form>
                    )}
                </>
            )}
        </Formik>
    );
};

export default observer(ImageEditor);
