import React, { Fragment, lazy } from 'react';
import { Route } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { MainLayout } from 'layout';
import { InteractionType } from '@azure/msal-browser';
import { scopes } from 'config/authConfig';
import NotFound from 'pages/NotFound';
import BusinessEventsManager from 'pages/BusinessEventsManager';
import BusinessEventConfigurator from 'pages/BusinessEventConfigurator/BusinessEventConfigurator';
import NomineeConfigurator from 'pages/NomineeConfigurator/NomineeConfigurator';
import ReferencesManager from 'pages/ReferencesManager';
import ReferenceConfigurator from 'pages/ReferenceConfigurator/ReferenceConfigurator';
import AttendeeDetails from 'pages/AttendeeDetails';

const routes = [
    {
        exact: true,
        secured: true,
        path: '/',
        layout: MainLayout,
        component: BusinessEventsManager,
    },
    {
        exact: true,
        secured: true,
        path: '/jeloltek',
        layout: MainLayout,
        component: lazy(() => import('pages/NomineeManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/jelentkezok',
        layout: MainLayout,
        component: lazy(() => import('pages/AttendeeManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/jelentkezok/jelentkezoreszletek',
        layout: MainLayout,
        component: AttendeeDetails,
    },
    {
        exact: true,
        secured: false,
        path: '/404',
        component: lazy(() => import('pages/NotFound')),
    },
    {
        exact: true,
        secured: true,
        path: '/esemenyszerkesztes',
        layout: MainLayout,
        component: BusinessEventConfigurator,
    },
    {
        exact: true,
        secured: true,
        path: '/jeloltek/jeloltszerkesztes',
        layout: MainLayout,
        component: NomineeConfigurator,
    },
    {
        exact: true,
        secured: true,
        path: '/referenciak',
        layout: MainLayout,
        component: ReferencesManager,
    },
    {
        exact: true,
        secured: true,
        path: '/referenciak/referenciaszerkesztes',
        layout: MainLayout,
        component: ReferenceConfigurator,
    },
];

export const renderRoutes = () => (
    <>
        <Route path="*" element={<NotFound />} />
        {routes.map((route, i) => {
            const Layout = route.layout || Fragment;
            const Component = route.component || Fragment;

            if (route.secured) {
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        element={
                            <MsalAuthenticationTemplate
                                interactionType={InteractionType.Redirect}
                                authenticationRequest={{ scopes: scopes }}
                            >
                                <Layout>
                                    <Component />
                                </Layout>
                            </MsalAuthenticationTemplate>
                        }
                    />
                );
            }

            return (
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                        <div>
                            <Layout>
                                <Component />
                            </Layout>
                        </div>
                    }
                />
            );
        })}
    </>
);

export default routes;
