import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, FormControl, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import agent from 'api/agent';
import { IUpdateableNominee } from 'models/nominee/updateableNominee';
import { IBusinessEventByName } from 'models/businessEvent/businessEventByName';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const GeneralInfoEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedNominee } = useContext(RootStoreContext).nomineeStore;
    const [isLoading, setIsLoading] = useState(false);

    const selectedBusinessEventId = selectedNominee.businessEvent.id;
    const [businessEvents, setBusinessEvents] = useState<IBusinessEventByName[]>([]);
    const [selectedOption, setSelectedOption] = useState<IBusinessEventByName>(null);

    const businessEventChange = (_, value) => {
        const name = value ? value.name : null;
        const option = businessEvents.find((x) => x.name == name);
        setSelectedOption(option);
    };

    useEffect(() => {
        (async () => {
            const allBusinessEvents = await agent.BusinessEvent.getAll();
            setBusinessEvents(allBusinessEvents);
            const option = allBusinessEvents.find((item) => item.id === selectedBusinessEventId);
            setSelectedOption(option);
        })();
    }, []);

    return (
        <Formik
            initialValues={{
                nomineeId: selectedNominee.id,
                businessEventId: selectedBusinessEventId,
                name: selectedNominee.name,
                description: selectedNominee.description,
                companyName: selectedNominee.companyName,
                role: selectedNominee.role,
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Töltsd ki ezt a mezőt!'),
                description: Yup.string().required('Töltsd ki ezt a mezőt!'),
                companyName: Yup.string().required('Töltsd ki ezt a mezőt!'),
                role: Yup.string().required('Töltsd ki ezt a mezőt!'),
            })}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                if (selectedOption === undefined) {
                    setSubmitting(false);
                    showErrorToast(`Kérem válasszon ki egy eseményt mentés előtt!`);
                    return;
                }

                const generalInformations: IUpdateableNominee = {
                    nomineeId: selectedNominee.id,
                    businessEventId: selectedOption.id,
                    name: values.name,
                    description: values.description,
                    role: values.role,
                    companyName: values.companyName,
                };

                agent.Nominee.update(generalInformations)
                    .then(() => {
                        showSuccessToast(`A (${values.name}) nevű jelölt módosítások elmentve!`);
                    })
                    .catch(() => showErrorToast(`A jelölt mentése sikertelen!`))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
                <>
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                            <Typography component="div">
                                <Box textAlign="center" m={1}>
                                    Betöltés...
                                </Box>
                            </Typography>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Név"
                                    type="text"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    error={Boolean(touched?.name && errors?.name)}
                                    helperText={touched?.name && errors?.name}
                                />
                            </Box>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Pozíció"
                                    type="text"
                                    name="role"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.role}
                                    error={Boolean(touched?.role && errors?.role)}
                                    helperText={touched?.role && errors?.role}
                                />
                            </Box>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Cég neve"
                                    type="text"
                                    name="companyName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyName}
                                    error={Boolean(touched?.companyName && errors?.companyName)}
                                    helperText={touched?.companyName && errors?.companyName}
                                />
                            </Box>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Leírás"
                                    type="text"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    error={Boolean(touched?.description && errors?.description)}
                                    helperText={touched?.description && errors?.description}
                                />
                            </Box>

                            <Box m={1}></Box>
                            <Box display="flex" m={1}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        id="combo-box-demo"
                                        options={businessEvents}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Esemény" />}
                                        onChange={businessEventChange}
                                        value={selectedOption}
                                    />
                                </FormControl>
                            </Box>

                            <Box m={1}></Box>
                            <Box display="flex" m={1}>
                                <Typography component="div">
                                    <Box textAlign="start" m={1}>
                                        Eredeti esemény neve: {selectedNominee.businessEvent.name}
                                    </Box>
                                </Typography>
                            </Box>
                            <Box m={1}></Box>
                            <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || isLoading}
                                    type="submit"
                                >
                                    Mentés
                                </Button>
                            </Box>
                        </form>
                    )}
                </>
            )}
        </Formik>
    );
};

export default observer(GeneralInfoEditor);
