import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import agent from 'api/agent';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import CreateNewSponsorsGrid from 'pages/BusinessEventsManager/components/CreateNewSponsorsGrid';
import { ISponsor } from 'models/businessEvent/sponsor';
import ExistingSponsors from './ExistingSponsors';
import { IUpdateSponsorsById } from 'models/businessEvent/updateSponsorsById';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const SponsorsEditor: React.FC = () => {
    const styles = useStyles();

    const { selectedBusinessEvent } = useContext(RootStoreContext).businessEventStore;
    const [sponsorImagesToUpload, setSponsorImagesToUpload] = useState<ImageUploadResult[]>([]);

    const [isUploadSponsorImageLoading, setIsUploadSponsorImageLoading] = useState(false);
    const [uploadLoadingMessage, setUploadLoadingMessage] = useState('');

    const [newSponsors, setNewSponsors] = useState<ISponsor[]>([]);
    const [existingSponsors, setExistingSponsors] = useState<ISponsor[]>(selectedBusinessEvent.sponsors);
    const [sponsorsToBeRemoved, setSponsorsToBeRemoved] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const onDeleteExistingImage = (logoLink: string) => {
        const reducedSponsors = existingSponsors.filter((item) => item.logoLink != logoLink);
        setExistingSponsors(reducedSponsors);
        const sponsors = sponsorsToBeRemoved.concat(logoLink);
        setSponsorsToBeRemoved(sponsors);
        setIsUploadSponsorImageLoading(false);
        setLoadingMessage(null);
    };

    const areSponsorsValid = (sponsors: ISponsor[]): boolean => {
        if (sponsors.length > 0) {
            const emptyLogoLink = sponsors.find((sponsor) => sponsor.sponsorLink === '');
            if (emptyLogoLink !== undefined) {
                showErrorToast(`Kérem adjon meg egy linket minden sponsorhoz!`);
                return false;
            }
        }
        return true;
    };

    return (
        <Formik
            initialValues={{
                id: selectedBusinessEvent.id,
            }}
            onSubmit={(_, { setSubmitting }) => {
                if (!areSponsorsValid(newSponsors)) {
                    setSubmitting(false);
                    return;
                }

                if (!areSponsorsValid(existingSponsors)) {
                    setSubmitting(false);
                    return;
                }

                const sponsorInfo: IUpdateSponsorsById = {
                    id: selectedBusinessEvent.id,
                    sponsorsToBeRemoved: sponsorsToBeRemoved,
                    sponsorsToBeAdded: newSponsors,
                    sponsorsToBeModified: existingSponsors,
                };
                agent.BusinessEvent.updateSponsorsById(sponsorInfo)
                    .then(() => {
                        showSuccessToast(`A(z) ${selectedBusinessEvent.name} nevű esemény képei frissítve lettek!`);
                        window.location.reload();
                    })
                    .catch(() => showErrorToast(`Az képek frissítése sikertelen volt!`))
                    .finally(() => {
                        setIsLoading(false);
                        setLoadingMessage(null);
                        setSubmitting(false);
                    });
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <>
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                            <Typography component="div">
                                <Box textAlign="center" m={1}>
                                    Betöltés...
                                </Box>
                            </Typography>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit} noValidate>
                            <ExistingSponsors
                                isLoading={isLoading}
                                loadingMessage={loadingMessage}
                                existingSponsors={existingSponsors}
                                setExistingSponsors={setExistingSponsors}
                                setUploadLoadingMessage={setUploadLoadingMessage}
                                setIsUploadSponsorImageLoading={setIsUploadSponsorImageLoading}
                                onDeleteExistingSponsor={onDeleteExistingImage}
                            />
                            <Box mt={2}>
                                {isUploadSponsorImageLoading ? (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <CircularProgress color="inherit" />
                                        {uploadLoadingMessage && (
                                            <Typography component="div">
                                                <Box textAlign="center" m={1}>
                                                    {uploadLoadingMessage}
                                                </Box>
                                            </Typography>
                                        )}
                                    </Box>
                                ) : (
                                    <CreateNewSponsorsGrid
                                        sponsors={newSponsors}
                                        setSponsors={setNewSponsors}
                                        imagesToUpload={sponsorImagesToUpload}
                                        setImagesToUpload={setSponsorImagesToUpload}
                                        isUploadImageLoading={isUploadSponsorImageLoading}
                                        setIsUploadImageLoading={setIsUploadSponsorImageLoading}
                                    />
                                )}
                            </Box>
                            <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || isLoading || isUploadSponsorImageLoading}
                                    type="submit"
                                >
                                    Mentés
                                </Button>
                            </Box>
                        </form>
                    )}
                </>
            )}
        </Formik>
    );
};

export default observer(SponsorsEditor);
