import { Button, Card, CardActions, CardMedia, DialogContentText } from '@mui/material';
import React from 'react';

const SimpleImageCard: React.FC<{
    imageUrl: string;
    buttons: { onButtonAction: () => void; buttonText: string; color: string }[];
    fileName?: string;
}> = ({ imageUrl, buttons, fileName }) => {
    return (
        <Card>
            <CardMedia component="img" image={imageUrl} onClick={() => window.open(imageUrl)} />
            {fileName && (
                <DialogContentText
                    style={{
                        fontSize: '18px',
                        fontStyle: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                    }}
                >
                    {fileName}
                </DialogContentText>
            )}

            {buttons.map((button) => (
                <CardActions style={{ justifyContent: 'center' }} key={button.buttonText}>
                    <Button
                        size="small"
                        color={button.color == 'error' ? 'error' : 'primary'}
                        onClick={button.onButtonAction}
                    >
                        {button.buttonText}
                    </Button>
                </CardActions>
            ))}
        </Card>
    );
};

export default SimpleImageCard;
