import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { SignOut } from './components';
import { makeStyles } from '@mui/styles';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { EventSeat, Menu } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    flexGrow: {
        flexGrow: 1,
    },
    toolbar: {
        minHeight: 64,
    },
    title: {
        marginLeft: 5,
        marginTop: 2,
    },
}));

type TopBarProps = {
    onSidebarOpen: () => void;
};

const TopBar: React.FC<TopBarProps> = ({ onSidebarOpen }) => {
    const styles = useStyles();

    return (
        <Fragment>
            <AppBar>
                <Toolbar className={styles.toolbar}>
                    <Hidden lgUp>
                        <IconButton color="inherit" onClick={onSidebarOpen}>
                            <Menu />
                        </IconButton>
                    </Hidden>
                    <Typography className={styles.title} variant="h5" color="inherit" align="center" component={'span'}>
                        Events & Awards - Adminiszrációs felület
                    </Typography>
                    <Box ml={2} flexGrow={1} />
                    {/*<Search />*/}
                    <SignOut />
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};

export default React.memo(observer(TopBar));
