import React, { useState } from 'react';
import { Box, CircularProgress, DialogContentText, Grid, Typography } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import ImageUploadWidget from 'components/ImageUploadWidget';
import agent from 'api/agent';
import { showErrorToast } from 'utils/customToaster';

const CreateNewImageGrid: React.FC<{
    thumbnailImageToUpload: ImageUploadResult;
    setThumbnailImageToUpload: React.Dispatch<React.SetStateAction<ImageUploadResult>>;
    isUploadImageLoading: boolean;
    setIsUploadImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ thumbnailImageToUpload, setThumbnailImageToUpload, setIsUploadImageLoading, isUploadImageLoading }) => {
    const [uploadThumbnailImageLoadingMessage, setUploadThumbnailImageLoadingMessage] = useState('');

    const deleteThumbnailImage = (imageUrl: string) => {
        agent.Image.deleteImage(imageUrl)
            .then(() => {
                setThumbnailImageToUpload(null);
            })
            .catch(() => showErrorToast(`A kép törlése sikertelen volt!`))
            .finally(() => {
                setIsUploadImageLoading(false);
            });
    };
    return (
        <>
            {isUploadImageLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {uploadThumbnailImageLoadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {uploadThumbnailImageLoadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    {thumbnailImageToUpload?.imageUrl ? (
                        <>
                            <DialogContentText>Új Előkép:</DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} key={thumbnailImageToUpload.imageUrl}>
                                    <SimpleImageCard
                                        imageUrl={thumbnailImageToUpload.imageUrl}
                                        buttons={[
                                            {
                                                onButtonAction: () => {
                                                    setUploadThumbnailImageLoadingMessage('Kép törlése...');
                                                    setIsUploadImageLoading(true);
                                                    deleteThumbnailImage(thumbnailImageToUpload.imageUrl);
                                                },
                                                buttonText: 'Törlés',
                                                color: 'error',
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box mt={2}>
                            <DialogContentText>Előkép feltöltése:</DialogContentText>
                            <ImageUploadWidget
                                setImageUrlToUpload={setThumbnailImageToUpload}
                                setIsLoading={setIsUploadImageLoading}
                                setLoadingMessage={setUploadThumbnailImageLoadingMessage}
                            />
                        </Box>
                    )}
                </div>
            )}
        </>
    );
};

export default CreateNewImageGrid;
