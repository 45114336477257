import React, { useContext } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RootStoreContext } from 'mobx/rootStore';
import { msalInstance } from 'config/authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '80%',
        height: 'auto',
    },
    name: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
}));

const Profile: React.FC = () => {
    const classes = useStyles();
    const accounts = msalInstance.getAllAccounts();
    //const { currentUser } = useContext(RootStoreContext).userStore;

    /*if (!currentUser) {
        return null;
    }*/

    return (
        <Box className={classes.root}>
            <img src={'/images/events_and_awards_logo_cropped.webp'} height={100} alt="Events & Awards Logo" />
            <Box my={1.5}>
                <Typography className={classes.name} variant="h4">
                    {accounts[0]?.name}
                </Typography>
            </Box>
            {/* <Box mb={1}>
                <Typography variant="body2">{ApplicationRoleLabel.get(currentUser.role)}</Typography>
</Box>*/}
        </Box>
    );
};

export default Profile;
