import React from 'react';
import { Grid, Theme, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    content: {
        paddingTop: 150,
        textAlign: 'center',
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560,
    },
}));

const NotFound: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div className={classes.content}>
                        <Typography variant="h1" mb={3}>
                            Hiba: 404
                        </Typography>
                        <Typography variant="h4" mb={3}>
                            A keresett oldal nem található
                        </Typography>

                        <Button href="/" variant="contained" color="primary" sx={{ width: '250px' }}>
                            Vissza az alkalmazásba
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotFound;
