import agent from 'api/agent';
import { action, computed, makeObservable, observable } from 'mobx';
import { IBusinessEvent } from 'models/businessEvent/businessEvent';
import { RootStore } from './rootStore';
import { IBusinessEventByName } from 'models/businessEvent/businessEventByName';

export default class BusinessEventStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable selectedBusinessEvent: IBusinessEvent | null = null;
    @observable filteredBusinessEvents: Map<string, IBusinessEvent> = new Map();
    @observable allBusinessEvents: Map<string, IBusinessEventByName> = new Map();

    @computed get getFilteredBusinessEvents(): IBusinessEvent[] {
        return Array.from(this.filteredBusinessEvents.values());
    }

    @computed get getAllBusinessEvents(): IBusinessEventByName[] {
        return Array.from(this.allBusinessEvents.values());
    }

    @action loadSelectedBusinessEvent = async (id: string): Promise<void> => {
        this.selectedBusinessEvent = await agent.BusinessEvent.getById(id);
    };
}
