/* eslint-disable no-console */
import _ from 'lodash';
import { colors, createTheme, responsiveFontSizes } from '@mui/material';
import typography from './typography';
import { THEMES } from './constants';

const baseConfig = {
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
    },
};

const themeConfigs = [
    {
        name: THEMES.LIGHT,
        // overrides: {
        //     MuiInputBase: {
        //         input: {
        //             '&::placeholder': {
        //                 opacity: 1,
        //                 color: colors.blueGrey[600]
        //             }
        //         }
        //     }
        // },
        palette: {
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f3f5f2',
                paper: colors.common.white,
            },
            primary: {
                main: '#e5d6b9',
            },
            secondary: {
                main: '#c5c5c5',
            },
            text: {
                primary: colors.common.black,
                secondary: colors.grey[900],
            },
        },
        // shadows: softShadows
    },
];

export function createCustomTheme(settings: any = {}) {
    let themeConfig = themeConfigs.find((theme) => theme.name === THEMES.LIGHT);

    if (!themeConfig) {
        console.warn(new Error(`The theme ${settings.theme} is not valid`));
        [themeConfig] = themeConfigs;
    }

    let theme = createTheme(_.merge({}, baseConfig, themeConfig, { direction: settings.direction }));

    if (settings.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
}
