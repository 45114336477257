import { Button, Card, CardActions, CardMedia, DialogContentText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
    media: {
        height: 200,
    },
});

const SimpleCard: React.FC<{
    buttons: { onButtonAction: () => void; buttonText: string; color: string }[];
    title?: string;
    url?: string;
}> = ({ buttons, title, url }) => {
    const classes = useStyles();

    return (
        <Card style={{ marginTop: '10px', width: 'fit-content' }}>
            {title && (
                <DialogContentText
                    style={{
                        fontSize: '16px',
                        fontStyle: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        paddingTop: '15px',
                        margin: '5px',
                    }}
                    onClick={() => window.open(url)}
                >
                    {title}
                </DialogContentText>
            )}

            {buttons.map((button) => (
                <CardActions style={{ justifyContent: 'center' }} key={button.buttonText}>
                    <Button
                        size="small"
                        color={button.color == 'error' ? 'error' : 'primary'}
                        onClick={button.onButtonAction}
                    >
                        {button.buttonText}
                    </Button>
                </CardActions>
            ))}
        </Card>
    );
};

export default SimpleCard;
