import React from 'react';
import { CircularProgress, DialogContentText, Typography, Box } from '@mui/material';
import FileUploadWidget from 'components/FileUploadWidget';
import { FileUploadResult } from 'models/common/fileUploadResult';

const CreateNewTnc: React.FC<{
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loadingMessage: string;
    setLoadingMessage: React.Dispatch<React.SetStateAction<string>>;
    setFileToUpload: React.Dispatch<React.SetStateAction<FileUploadResult>>;
    fileToUpload: FileUploadResult;
}> = ({ isLoading, setIsLoading, loadingMessage, setLoadingMessage, setFileToUpload, fileToUpload }) => {
    return (
        <Box mt={2}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {loadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {loadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    {
                        <Box mt={2}>
                            <DialogContentText>Általános Szerződési Feltételek feltöltése:</DialogContentText>
                            <FileUploadWidget
                                setFileUploadResult={setFileToUpload}
                                setIsLoading={setIsLoading}
                                setLoadingMessage={setLoadingMessage}
                                fileUploadResult={fileToUpload}
                            />
                        </Box>
                    }
                </div>
            )}
        </Box>
    );
};

export default CreateNewTnc;
