import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, DialogContentText, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import agent from 'api/agent';
import { IUpdateableReference } from 'models/reference/updateableReference';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const getYoutubeLinkIdFromUrl = (youtubeLink?): string | null => {
    if (!youtubeLink) return null;
    return youtubeLink.replace('https://www.youtube.com/watch?v=', '');
};

const GeneralInfoEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedReference } = useContext(RootStoreContext).referenceStore;

    return (
        <Formik
            initialValues={{
                ...selectedReference,
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Töltsd ki ezt a mezőt!'),
            })}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                const youtubeLinkId = getYoutubeLinkIdFromUrl(values.youtubeLinkId);
                const generalInformations: IUpdateableReference = {
                    id: selectedReference.id,
                    name: values.name,
                    youtubeLinkId: youtubeLinkId,
                };

                agent.Reference.update(generalInformations)
                    .then(() => {
                        showSuccessToast(`Az esemény (${values.name}) módosítások elmentve!`);
                    })
                    .catch(() => showErrorToast(`Az esemény mentése sikertelen!`))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
                <>
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                            <Typography component="div">
                                <Box textAlign="center" m={1}>
                                    Betöltés...
                                </Box>
                            </Typography>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit} noValidate>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Név"
                                    type="text"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    error={Boolean(touched?.name && errors?.name)}
                                    helperText={touched?.name && errors?.name}
                                />
                            </Box>
                            <Box display="flex" m={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Youtube link"
                                    type="text"
                                    name="youtubeLinkId"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.youtubeLinkId}
                                />
                                <DialogContentText>
                                    (Példa Url: https://www.youtube.com/watch?v=abcdefgijk)
                                </DialogContentText>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                <Button variant="contained" color="primary" disabled={isSubmitting} type="submit">
                                    Mentés
                                </Button>
                            </Box>
                        </form>
                    )}
                </>
            )}
        </Formik>
    );
};

export default observer(GeneralInfoEditor);
