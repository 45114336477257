import { CircularProgress, Box, Typography, DialogContentText, Grid, TextField } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import { ISponsor } from 'models/businessEvent/sponsor';
import React from 'react';

const ExistingSponsors: React.FC<{
    isLoading: boolean;
    loadingMessage: string;
    existingSponsors: ISponsor[];
    setExistingSponsors: React.Dispatch<React.SetStateAction<ISponsor[]>>;
    setUploadLoadingMessage: (value: React.SetStateAction<string>) => void;
    setIsUploadSponsorImageLoading: (value: React.SetStateAction<boolean>) => void;
    onDeleteExistingSponsor: (logoLink: string) => void;
}> = ({
    isLoading,
    loadingMessage,
    setUploadLoadingMessage,
    existingSponsors,
    setExistingSponsors,
    setIsUploadSponsorImageLoading,
    onDeleteExistingSponsor,
}) => {
    const handleSponsorLinkChange = (sponsorIndex: number, newSponsorLink: string) => {
        setExistingSponsors((prevSponsors) => {
            const updatedSponsors = [...prevSponsors];
            updatedSponsors[sponsorIndex] = { ...updatedSponsors[sponsorIndex], sponsorLink: newSponsorLink };
            return updatedSponsors;
        });
    };
    return (
        <Box mt={2}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {loadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {loadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    <DialogContentText>Már feltöltött sponsorok:</DialogContentText>
                    {existingSponsors.length > 0 ? (
                        <Grid container spacing={1}>
                            {existingSponsors.map((sponsor, sponsorIndex) => (
                                <Grid item xs={12} sm={6} key={sponsor.logoLink}>
                                    <SimpleImageCard
                                        imageUrl={sponsor.logoLink}
                                        buttons={[
                                            {
                                                onButtonAction: () => {
                                                    setUploadLoadingMessage('Sponsor törlése...');
                                                    setIsUploadSponsorImageLoading(true);
                                                    onDeleteExistingSponsor(sponsor.logoLink);
                                                },
                                                buttonText: 'Törlés',
                                                color: 'error',
                                            },
                                        ]}
                                    />
                                    <Box display="flex" mb={1} style={{ paddingTop: '10px' }}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Sponsor link"
                                            name="sponsor"
                                            value={sponsor.sponsorLink}
                                            onChange={(e) => handleSponsorLinkChange(sponsorIndex, e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Box mt={2}>
                            <DialogContentText style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                Nincsenek még feltöltött sponsorok
                            </DialogContentText>
                        </Box>
                    )}
                </div>
            )}
        </Box>
    );
};
export default ExistingSponsors;
