import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import agent from 'api/agent';
import FilesDropzoneWidget from 'components/FileDropzoneWidget';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { showErrorToast } from 'utils/customToaster';

const ImagesUploadWidget: React.FC<{
    setImageUrlToUpload?: React.Dispatch<React.SetStateAction<any[]>>;
    setLoadingMessage: any;
    setIsLoading: any;
}> = ({ setImageUrlToUpload, setLoadingMessage, setIsLoading }) => {
    const [images, setImages] = useState([]);
    const acceptedMimeTypes = ['image/jpeg', 'image/png'];

    const addImages = () => {
        setLoadingMessage('Kép(ek) hozzáadása...');
        setIsLoading(true);
        agent.Image.uploadImages(images)
            .then((imageUploadResult: ImageUploadResult[]) => {
                if (setImageUrlToUpload) {
                    setImageUrlToUpload((oldArray) => [...oldArray, ...imageUploadResult]);
                }
                setImages([]);
            })
            .catch(() => {
                showErrorToast('Hiba történt, kérem próbáljon meg egyszerre kevesebb képet feltölteni!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FilesDropzoneWidget setFiles={setImages} currentFiles={images} mimeTypes={acceptedMimeTypes} />
                    </Grid>
                    <Grid item xs={12}>
                        {images.length > 0 && (
                            <Box mt={2}>
                                <Button variant="contained" color="secondary" onClick={addImages}>
                                    Képek Feltöltése
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ImagesUploadWidget;
