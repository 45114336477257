import React from 'react';
import { colors, Divider, Drawer, Theme } from '@mui/material';
import { Nav, Profile } from './components';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import { useRootStore } from 'mobx/rootStore';

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)',
        },
    },
    root: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
    title: {
        color: '#616161',
        textTransform: 'none',
    },
    button: {
        color: '#616161',
        // padding: '10px 8px',
        justifyContent: 'flex-start',
        alignItems: 'left',
        textTransform: 'none',
        letterSpacing: 0,
        // width: 'auto',
        // background: colors.grey[400],
        backgroundColor: 'transparent',
    },
    icon: {
        color: colors.grey[700],
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
    },
}));

interface INavBarProps {
    onClose: () => void;
    open: boolean;
    variant: 'persistent' | 'temporary';
    pages: { id: number; title: string; href: string; icon: React.ReactNode }[];
}

const NavBar: React.FC<INavBarProps> = ({ onClose, open, pages, variant }) => {
    const styles = useStyles();
    //const { currentUser } = useRootStore().userStore;

    return (
        <Drawer anchor="left" classes={{ paper: styles.drawer }} onClose={onClose} open={open} variant={variant}>
            <div className={styles.root}>
                <Profile />
                <Divider className={styles.divider} />
                <Nav onClose={onClose} className={styles.nav} pages={pages} />
            </div>
        </Drawer>
    );
};

export default React.memo(observer(NavBar));
