import React from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item>
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    <Link to="/">
                        <span style={{ color: 'black' }}>Főoldal</span>
                    </Link>
                    <Typography variant="body1" color="textPrimary">
                        Jelentkezők kezelése
                    </Typography>
                </Breadcrumbs>
                <Typography variant="h3" color="textPrimary">
                    Jelentkező részletei
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
