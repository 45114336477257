import React from 'react';
import { History, Groups, GroupsTwoTone, Home } from '@mui/icons-material';

export default [
    {
        id: 0,
        title: 'Események kezelése',
        href: '/',
        icon: <Home />,
    },
    {
        id: 1,
        title: 'Jelöltek kezelése',
        href: '/jeloltek',
        icon: <Groups />,
    },
    {
        id: 2,
        title: 'Jelentkezők kezelése',
        href: '/jelentkezok',
        icon: <GroupsTwoTone />,
    },
    {
        id: 3,
        title: 'Referenciák kezelése',
        href: '/referenciak',
        icon: <History />,
    },
];
