import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { Routes } from 'react-router-dom';
import { Backdrop, Button, CircularProgress, ThemeProvider } from '@mui/material';
import useSettings from 'hooks/useSettings';
import { createCustomTheme } from 'theme';
import { renderRoutes } from 'navigation/routes';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'react-quill/dist/quill.snow.css';

const ErrorFallback = () => {
    return (
        <div className="text-red-500 w-screen h-screen flex flex-col justify-center items-center" role="alert">
            <h2 className="text-lg font-semibold">Ooops, ismeretlen hiba történt!</h2>
            <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
                Oldal újratöltése
            </Button>
        </div>
    );
};

const App: React.FC<{ instance: PublicClientApplication }> = ({ instance }) => {
    const { settings }: any = useSettings();

    return (
        <React.Suspense
            fallback={
                <>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            }
        >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Router>
                    <MsalProvider instance={instance}>
                        <ThemeProvider theme={createCustomTheme(settings)}>
                            <HelmetProvider>
                                <Routes>{renderRoutes()}</Routes>
                                <ToastContainer />
                            </HelmetProvider>
                        </ThemeProvider>
                    </MsalProvider>
                </Router>
            </ErrorBoundary>
        </React.Suspense>
    );
};

export default App;
