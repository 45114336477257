import agent from 'api/agent';
import { action, computed, makeObservable, observable } from 'mobx';
import { IAttendee } from 'models/attendee/attendee';
import { RootStore } from './rootStore';
import { ILessDetailedAttendee } from 'models/attendee/lessDetailedAttendee';

export default class AttendeeStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable selectedAttendee: IAttendee | null = null;
    @observable filteredAttendeess: Map<string, ILessDetailedAttendee> = new Map();

    @computed get getFilteredAttendees(): ILessDetailedAttendee[] {
        return Array.from(this.filteredAttendeess.values());
    }

    @action loadSelectedAttendee = async (id: string): Promise<void> => {
        this.selectedAttendee = await agent.Attendee.getById(id);
    };
}
