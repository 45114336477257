import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import pages from 'layout/components/NavBar/pages';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavBar, TopBar } from './components';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
    },
    shiftContent: {
        paddingLeft: 240,
    },
    content: {
        height: '100%',
    },
}));

type MainLayoutProps = {
    children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });
    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <>
            <div
                className={clsx({
                    [classes.root]: true,
                    [classes.shiftContent]: isDesktop,
                })}
            >
                <div>
                    <TopBar onSidebarOpen={handleSidebarOpen} />
                    <NavBar
                        onClose={handleSidebarClose}
                        open={shouldOpenSidebar}
                        pages={pages}
                        variant={isDesktop ? 'persistent' : 'temporary'}
                    />
                    {<main className={classes.content}>{children}</main>}
                </div>
            </div>
        </>
    );
};

export default React.memo(observer(MainLayout));
