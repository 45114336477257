import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import agent from 'api/agent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { INewReference } from 'models/reference/newReference';
import CreateNewImagesGrid from 'components/Common/ImagesComponents/CreateNewImagesGrid';
import CreateNewImageGrid from 'components/Common/SingleImageComponents/CreateNewImageGrid';

const CreateNewReferenceDialog: React.FC<{
    setIsCreateModeEnabled: (isEnabled: boolean) => void;
    setReloadRequest: (isEnabled: boolean) => void;
    isCreateModeEnabled: boolean;
}> = ({ setIsCreateModeEnabled, setReloadRequest, isCreateModeEnabled }) => {
    const [isDialogLoading, setDialogLoading] = useState(false);

    const [thumbnailImageToUpload, setThumbnailImageToUpload] = useState<ImageUploadResult>(null);
    const [isUploadThumbnailImageLoading, setIsUploadThumbnailImageLoading] = useState(false);

    const [imagesToUpload, setImagesToUpload] = useState<ImageUploadResult[]>([]);
    const [isUploadImagesLoading, setIsUploadImagesLoading] = useState(false);

    const getYoutubeLinkIdFromUrl = (youtubeLink?): string | null => {
        if (!youtubeLink) return null;
        return youtubeLink.replace('https://www.youtube.com/watch?v=', '');
    };

    const clearData = () => {
        setImagesToUpload([]);
        setThumbnailImageToUpload(null);
        setIsCreateModeEnabled(false);
    };

    return (
        <Dialog
            open={isCreateModeEnabled}
            onClose={() => setIsCreateModeEnabled(false)}
            fullWidth={true}
            fullScreen={true}
        >
            <DialogTitle>REFERENCIA LÉTREHOZÁSA</DialogTitle>
            {isDialogLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ minHeight: '120px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Formik
                    initialValues={{
                        name: '',
                        images: [],
                        thumbnailImage: '',
                        youtubeLinkId: null,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Töltsd ki ezt a mezőt!'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        if (imagesToUpload.length == 0) {
                            setSubmitting(false);
                            showErrorToast(`Kérem töltsön fel referencia képeket!`);
                            return;
                        }

                        if (thumbnailImageToUpload == null) {
                            setSubmitting(false);
                            showErrorToast(`Kérem töltsön fel előképet!`);
                            return;
                        }
                        const youtubeLinkId = getYoutubeLinkIdFromUrl(values.youtubeLinkId);

                        const newReference: INewReference = {
                            name: values.name,
                            images: imagesToUpload.map((image) => image.imageUrl),
                            thumbnailImage: thumbnailImageToUpload?.imageUrl,
                            youtubeLinkId: youtubeLinkId,
                        };
                        agent.Reference.create(newReference)
                            .then(() => {
                                showSuccessToast(`Az új referencia (${newReference.name}) elmentve!`);
                                setReloadRequest(true);
                                clearData();
                            })
                            .catch(() => showErrorToast(`A referencia mentése sikertelen!`))
                            .finally(() => {
                                setSubmitting(false);
                                setDialogLoading(false);
                            });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogContent>
                                <Box display="flex" mb={1}>
                                    <TextField
                                        error={Boolean(touched?.name && errors?.name)}
                                        fullWidth
                                        variant="outlined"
                                        helperText={touched?.name && errors?.name}
                                        label="Név"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                    />
                                </Box>
                                <Box display="flex" mb={1}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Youtube link"
                                        name="youtubeLinkId"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.youtubeLinkId}
                                    />
                                </Box>
                                <DialogContentText>
                                    (Példa Url: https://www.youtube.com/watch?v=abcdefgijk)
                                </DialogContentText>
                                <Box display="flex"></Box>
                                <Box mt={2}>
                                    <CreateNewImageGrid
                                        thumbnailImageToUpload={thumbnailImageToUpload}
                                        setThumbnailImageToUpload={setThumbnailImageToUpload}
                                        isUploadImageLoading={isUploadThumbnailImageLoading}
                                        setIsUploadImageLoading={setIsUploadThumbnailImageLoading}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <CreateNewImagesGrid
                                        imagesToUpload={imagesToUpload}
                                        setImagesToUpload={setImagesToUpload}
                                        isUploadImageLoading={isUploadImagesLoading}
                                        setIsUploadImageLoading={setIsUploadImagesLoading}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={
                                        isSubmitting ||
                                        isDialogLoading ||
                                        isUploadImagesLoading ||
                                        isUploadThumbnailImageLoading
                                    }
                                    onClick={() => setIsCreateModeEnabled(false)}
                                >
                                    Mégsem
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        isDialogLoading ||
                                        isUploadImagesLoading ||
                                        isUploadThumbnailImageLoading
                                    }
                                >
                                    Mentés
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            )}
        </Dialog>
    );
};

export default CreateNewReferenceDialog;
