import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import agent from 'api/agent';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { IUpdateImages } from 'models/common/updateImageById';
import CreateNewImagesGrid from 'components/Common/ImagesComponents/CreateNewImagesGrid';
import ExistingImages from 'components/Common/ImagesComponents/ExistingImages';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const ImagesEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedReference } = useContext(RootStoreContext).referenceStore;
    const [existingImages, setExistingImages] = useState<string[]>(
        selectedReference.images.map((image) => image.imageLink),
    );
    const [imagesToBeRemoved, setImagesToBeRemoved] = useState<string[]>([]);
    const [imagesToUpload, setImagesToUpload] = useState<ImageUploadResult[]>([]);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadImageLoading, setIsUploadImageLoading] = useState(false);
    const [uploadLoadingMessage, setUploadLoadingMessage] = useState('');

    const onDeleteExistingImage = (image: string) => {
        const reducedImages = existingImages.filter((item) => item != image);
        setExistingImages(reducedImages);
        const images = imagesToBeRemoved.concat(image);
        setImagesToBeRemoved(images);
        setIsUploadImageLoading(false);
        setUploadLoadingMessage(null);
    };

    return (
        <>
            <Formik
                initialValues={{
                    id: selectedReference.id,
                    imagesToBeRemoved: imagesToBeRemoved,
                    imagesToUpload: imagesToUpload,
                }}
                validationSchema={Yup.object().shape({})}
                enableReinitialize
                onSubmit={(_, { setSubmitting }) => {
                    const imageInformation: IUpdateImages = {
                        id: selectedReference.id,
                        imagesToBeRemoved: imagesToBeRemoved,
                        imagesToBeAdded: imagesToUpload.map((image) => image.imageUrl),
                    };
                    agent.Reference.updateImages(imageInformation)
                        .then(() => {
                            showSuccessToast(`A(z) ${selectedReference.name} nevű referencia képei frissítve lettek!`);
                            window.history.back();
                        })
                        .catch(() => showErrorToast(`Az referencia képeinek frissítése sikertelen volt!`))
                        .finally(() => {
                            setIsLoading(false);
                            setLoadingMessage(null);
                            setSubmitting(false);
                        });
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <>
                        {isSubmitting ? (
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                <CircularProgress />
                                <Typography component="div">
                                    <Box textAlign="center" m={1}>
                                        Betöltés...
                                    </Box>
                                </Typography>
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit} noValidate>
                                <ExistingImages
                                    isLoading={isLoading}
                                    loadingMessage={loadingMessage}
                                    existingImages={existingImages}
                                    setUploadLoadingMessage={setUploadLoadingMessage}
                                    setIsUploadImageLoading={setIsUploadImageLoading}
                                    onDeleteExistingImage={onDeleteExistingImage}
                                />
                                <Box mt={2}>
                                    <CreateNewImagesGrid
                                        imagesToUpload={imagesToUpload}
                                        setImagesToUpload={setImagesToUpload}
                                        isUploadImageLoading={isUploadImageLoading}
                                        setIsUploadImageLoading={setIsUploadImageLoading}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || isLoading || isUploadImageLoading}
                                        type="submit"
                                    >
                                        Mentés
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </>
                )}
            </Formik>
        </>
    );
};

export default observer(ImagesEditor);
