import React, { useState } from 'react';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import agent from 'api/agent';
import FileDropzoneWidget from 'components/FileDropzoneWidget/FileDropzoneWidget';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { FileUploadResult } from 'models/common/fileUploadResult';
import SimpleCard from 'components/SimpleCard';

const FileUploadWidget: React.FC<{
    setFileUploadResult?: any;
    setLoadingMessage: any;
    setIsLoading: any;
    fileUploadResult?: FileUploadResult;
}> = ({ setFileUploadResult, setLoadingMessage, setIsLoading, fileUploadResult }) => {
    const [file, setFile] = useState(null);

    const acceptedMimeTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const deleteFile = () => {
        setIsLoading(true);
        setLoadingMessage('Fájl törlése...');
        agent.File.deleteFile(fileUploadResult?.url)
            .then(() => {
                showSuccessToast(`A feltöltött fájl törölve!`);
                setFileUploadResult(null);
            })
            .catch(() => showErrorToast(`A fájl törlése sikertelen!`))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const buttons = [
        {
            onButtonAction: () => {
                window.open(fileUploadResult?.url);
            },
            buttonText: 'Megtekintés',
            color: 'primary',
        },
        {
            onButtonAction: () => {
                {
                    deleteFile();
                }
            },
            buttonText: 'Törlés',
            color: 'error',
        },
    ];

    const addFile = () => {
        setLoadingMessage('Fájl hozzáadása...');
        setIsLoading(true);
        agent.File.uploadFile(file[0])
            .then((fileUploadResult: FileUploadResult) => {
                if (fileUploadResult) {
                    setFileUploadResult(fileUploadResult);
                    showSuccessToast('Fájl feltöltve!');
                } else {
                    showErrorToast('Fájl feltöltése sikertelen!');
                }
                setFile(null);
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    showErrorToast('Fájl már létezik!');
                } else {
                    showErrorToast('Fájl feltöltése sikertelen!');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" padding={'15px'}>
                <Grid container spacing={2}>
                    {fileUploadResult ? (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={2} key={file}>
                                    <SimpleCard
                                        url={fileUploadResult?.url}
                                        buttons={buttons}
                                        title={fileUploadResult?.fileName}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <FileDropzoneWidget setFile={setFile} mimeTypes={acceptedMimeTypes} />
                            </Grid>
                            <Grid item xs={12}>
                                {file && (
                                    <Box mt={2}>
                                        <Button variant="contained" color="secondary" onClick={addFile}>
                                            Fájl Feltöltése
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default FileUploadWidget;
