import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Container,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
} from '@mui/material';
import { Delete, InfoOutlined, Image } from '@mui/icons-material';
import Header from './components/Header';
import EditorElement from '../../components/EditorElement';
import CustomPage from 'components/CustomPage';
import { useRootStore } from 'mobx/rootStore';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import agent from 'api/agent';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import GeneralInfoEditor from './components/GeneralInfoEditor';
import ImageEditor from './components/ImageEditor';

const NomineeConfigurator: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { loadSelectedNominee, selectedNominee } = useRootStore().nomineeStore;
    const [isLoading, setLoading] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const nomineeId = searchParams.get('id');

    useEffect(() => {
        setLoading(true);
        loadSelectedNominee(nomineeId).finally(() => {
            setLoading(false);
        });
    }, []);

    const deleteNominee = () => {
        setIsDeleteLoading(true);
        setLoadingMessage('Jelölt törlése folyamatban');
        agent.Nominee.delete(selectedNominee.id.toString())
            .then(() => {
                showSuccessToast(`A(z) ${selectedNominee.name} nevű jelölt törölve lett!`);
                window.history.back();
            })
            .catch(() => showErrorToast(`Az jelölt törlése sikertelen volt!`))
            .finally(() => {
                setIsDeleteLoading(false);
                setLoadingMessage(null);
            });
    };

    return (
        <CustomPage title="Jelöltek szerkesztése | Jelöltek és díjak - Admin">
            <Container maxWidth={false}>
                <Header isLoading={isLoading} />
                {isLoading || !selectedNominee ? (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                        <CircularProgress />
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                Betöltés...
                            </Box>
                        </Typography>
                    </Box>
                ) : (
                    <>
                        {isDeleteLoading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                <CircularProgress color="inherit" />
                                {loadingMessage && (
                                    <Typography component="div">
                                        <Box textAlign="center" m={1}>
                                            {loadingMessage}
                                        </Box>
                                    </Typography>
                                )}
                            </Box>
                        ) : (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card sx={{ width: '100%' }}>
                                        <CardHeader title="Szerkesztő" />
                                        <Divider />
                                        <CardContent>
                                            <Box>
                                                <EditorElement
                                                    sectionTitle="Általános információk"
                                                    statusIcon={InfoOutlined}
                                                    content={<GeneralInfoEditor />}
                                                />
                                                <EditorElement
                                                    sectionTitle="Kép"
                                                    statusIcon={Image}
                                                    content={<ImageEditor />}
                                                />

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    padding={'20px'}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => setAlertOpen(true)}
                                                    >
                                                        <Delete />
                                                        Jelölt törlése
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Dialog open={isAlertOpen} onClose={() => setAlertOpen(false)}>
                                        <DialogTitle>{'FIGYELEM!'}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Biztosan törlöd az {selectedNominee.name} nevű jelöltet? <br />
                                                Ez a művelet nem visszavonható!
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setAlertOpen(false)}
                                            >
                                                Mégsem
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    deleteNominee();
                                                    setAlertOpen(false);
                                                }}
                                            >
                                                Igen
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Container>
        </CustomPage>
    );
};

export default observer(NomineeConfigurator);
