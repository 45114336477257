import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, DialogContentText, Grid, TextField, Typography } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import ImageUploadWidget from 'components/ImagesUploadWidget';
import agent from 'api/agent';
import { ISponsor } from 'models/businessEvent/sponsor';

const CreateNewSponsorsGrid: React.FC<{
    imagesToUpload: ImageUploadResult[];
    setImagesToUpload: (imageUploadResults: ImageUploadResult[]) => void;
    sponsors: ISponsor[];
    setSponsors: React.Dispatch<React.SetStateAction<ISponsor[]>>;
    setIsUploadImageLoading: (isLoading: boolean) => void;
    isUploadImageLoading: boolean;
}> = ({ imagesToUpload, setImagesToUpload, sponsors, setSponsors, isUploadImageLoading, setIsUploadImageLoading }) => {
    const [uploadLoadingMessage, setUploadLoadingMessage] = useState('');

    useEffect(() => {
        updateSponsors();
    }, [imagesToUpload]);

    const updateSponsors = () => {
        const existingSponsorLinksMap = new Map(sponsors.map(({ logoLink, sponsorLink }) => [logoLink, sponsorLink]));

        const updatedSponsors = imagesToUpload.map((logoLink) => ({
            sponsorLink: existingSponsorLinksMap.get(logoLink.imageUrl) || '',
            logoLink: logoLink.imageUrl,
        }));

        setSponsors(updatedSponsors);
    };

    const deleteImage = (indexImageUrl: string) => {
        agent.Image.deleteImage(indexImageUrl)
            .then(() => {
                const filteredImages = imagesToUpload.filter((image) => image.imageUrl !== indexImageUrl);
                setImagesToUpload(filteredImages);
            })
            .finally(() => {
                setIsUploadImageLoading(false);
            });
    };

    const handleSponsorLinkChange = (sponsorIndex: number, newSponsorLink: string) => {
        setSponsors((prevSponsors) => {
            const updatedSponsors = [...prevSponsors];
            updatedSponsors[sponsorIndex] = { ...updatedSponsors[sponsorIndex], sponsorLink: newSponsorLink };
            return updatedSponsors;
        });
    };

    return (
        <>
            {isUploadImageLoading ? (
                <Box mt={2} display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {uploadLoadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {uploadLoadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <>
                    <Box mt={2}>
                        <DialogContentText>Sponsorok feltöltése: (Példa Url: https://www.google.com)</DialogContentText>
                        <ImageUploadWidget
                            setImageUrlToUpload={setImagesToUpload}
                            setIsLoading={setIsUploadImageLoading}
                            setLoadingMessage={setUploadLoadingMessage}
                        />
                    </Box>

                    {sponsors.length > 0 && (
                        <>
                            <DialogContentText>Új Sponsorok:</DialogContentText>
                            <Grid container spacing={1}>
                                {sponsors.map((sponsor, sponsorIndex) => (
                                    <Grid item xs={12} sm={6} key={sponsor.logoLink}>
                                        <SimpleImageCard
                                            imageUrl={sponsor.logoLink}
                                            buttons={[
                                                {
                                                    onButtonAction: () => {
                                                        setUploadLoadingMessage('Sponsor törlése...');
                                                        setIsUploadImageLoading(true);
                                                        deleteImage(sponsor.logoLink);
                                                    },
                                                    buttonText: 'Törlés',
                                                    color: 'error',
                                                },
                                            ]}
                                        />
                                        <Box display="flex" mb={1} style={{ paddingTop: '10px' }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Sponsor link"
                                                name="sponsor"
                                                value={sponsor.sponsorLink}
                                                onChange={(e) => handleSponsorLinkChange(sponsorIndex, e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default CreateNewSponsorsGrid;
