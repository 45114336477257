import { CircularProgress, Box, Typography, DialogContentText, Grid } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import React from 'react';

const ExistingImages: React.FC<{
    isLoading: boolean;
    loadingMessage: string;
    existingImages: string[];
    setUploadLoadingMessage: (value: React.SetStateAction<string>) => void;
    setIsUploadImageLoading: (value: React.SetStateAction<boolean>) => void;
    onDeleteExistingImage: (image: string) => void;
}> = ({
    isLoading,
    loadingMessage,
    setUploadLoadingMessage,
    existingImages,
    setIsUploadImageLoading,
    onDeleteExistingImage,
}) => {
    return (
        <Box mt={2}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {loadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {loadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    <DialogContentText>Már feltöltött képek:</DialogContentText>
                    {existingImages.length > 0 ? (
                        <Grid container spacing={1}>
                            {existingImages.map((image) => (
                                <Grid item xs={12} sm={6} key={image}>
                                    <SimpleImageCard
                                        imageUrl={image}
                                        buttons={[
                                            {
                                                onButtonAction: () => {
                                                    setUploadLoadingMessage('Kép törlése...');
                                                    setIsUploadImageLoading(true);
                                                    onDeleteExistingImage(image);
                                                },
                                                buttonText: 'Törlés',
                                                color: 'error',
                                            },
                                        ]}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Box mt={2}>
                            <DialogContentText style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                Nincsenek még feltöltött képek
                            </DialogContentText>
                        </Box>
                    )}
                </div>
            )}
        </Box>
    );
};
export default ExistingImages;
