import { msalInstance, scopes } from 'config/authConfig';

export const acquireAccessToken = async (): Promise<string> => {
    try {
        const accounts = msalInstance.getAllAccounts();

        const authRequest = {
            scopes: scopes,
            account: accounts[0],
        };

        const authResponse = await msalInstance.acquireTokenSilent(authRequest);

        return authResponse?.accessToken;
    } catch (error) {
        // If an error is thrown than most probably user is not logged in properly.
        // TODO: Review it.
        msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
    }
};
