import { createContext, useContext } from 'react';
import { configure } from 'mobx';
import NomineeStore from './nomineeStore';
import ReferenceStore from './referenceStore';
import BusinessEventStore from './businessEventStore';
import AttendeeStore from './attendeeStore';

configure({ enforceActions: 'always' });

export class RootStore {
    nomineeStore: NomineeStore;
    referenceStore: ReferenceStore;
    businessEventStore: BusinessEventStore;
    attendeeStore: AttendeeStore;

    constructor() {
        this.nomineeStore = new NomineeStore(this);
        this.referenceStore = new ReferenceStore(this);
        this.businessEventStore = new BusinessEventStore(this);
        this.attendeeStore = new AttendeeStore(this);
    }
}

const rootStore = new RootStore();
export const RootStoreContext = createContext(rootStore);

export function useRootStore(): RootStore {
    return useContext(RootStoreContext);
}
