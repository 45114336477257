import { action, computed, makeObservable, observable } from 'mobx';
import { INominee } from 'models/nominee/nominee';
import { RootStore } from './rootStore';
import agent from 'api/agent';
import { ILessDetailedNominee } from 'models/nominee/lessDetailedNominee';

export default class NomineeStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable selectedNominee: INominee | null = null;
    @observable filteredNominees: Map<string, ILessDetailedNominee> = new Map();

    @computed get getFilteredNominees(): ILessDetailedNominee[] {
        return Array.from(this.filteredNominees.values());
    }

    @action loadSelectedNominee = async (id: string): Promise<void> => {
        this.selectedNominee = await agent.Nominee.getById(id);
    };
}
