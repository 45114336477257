/* eslint-disable @typescript-eslint/ban-types */
import { BackupOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const dropzoneStyles = {
    border: 'dashed 3px',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    textAlign: 'center' as const,
    height: '100px',
};

const dropzoneActive = {
    borderColor: 'green',
};

const FileDropzoneWidget: React.FC<{
    setFile: (file: object) => void;
    mimeTypes: string[];
}> = ({ setFile, mimeTypes }) => {
    const onDrop = useCallback(
        (fileToSet) => {
            setFile(
                fileToSet.map((file: object) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
        [setFile],
    );

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop,
        accept: mimeTypes.join(','),
        maxFiles: 1,
    });

    const files = acceptedFiles.map((file: any) => (
        <li key={file.path}>
            {file.path} - {file.size / 1000000} MB
        </li>
    ));

    return (
        <div>
            <div {...getRootProps()} style={isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles}>
                <input {...getInputProps()} />
                <BackupOutlined />
                <Typography variant="h6" gutterBottom>
                    Kattints ide a fájl kiválasztásához!
                </Typography>
            </div>
            <ul>{files}</ul>
        </div>
    );
};

export default FileDropzoneWidget;
