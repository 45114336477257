/* eslint-disable @typescript-eslint/ban-types */
import { BackupOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const dropzoneStyles = {
    border: 'dashed 3px',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    textAlign: 'center' as const,
    height: '100px',
};

const dropzoneActive = {
    borderColor: 'green',
};

const FilesDropzoneWidget: React.FC<{
    setFiles: React.Dispatch<React.SetStateAction<any[]>>;
    currentFiles: any[];
    mimeTypes: string[];
}> = ({ setFiles, currentFiles, mimeTypes }) => {
    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = getNewFiles(acceptedFiles);

        setFiles((oldFiles) => {
            const filteredNewFiles = filterOutAlreadyAddedFiles(newFiles, oldFiles);
            return [...oldFiles, ...filteredNewFiles];
        });
    }, []);

    const getNewFiles = (acceptedFiles): any[] => {
        return acceptedFiles.map((file: object) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            }),
        );
    };

    const filterOutAlreadyAddedFiles = (newFiles: any[], oldFiles: any[]): any[] => {
        return newFiles.filter((newFile) => {
            return !oldFiles.some((oldFile) => oldFile?.path === newFile?.path);
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: mimeTypes.join(','),
    });

    const removeItem = (itemToRemove) => {
        const updatedItems = currentFiles.filter((item) => item !== itemToRemove);
        setFiles(updatedItems);
    };

    const files = currentFiles.map((file: any) => (
        <li key={file.path} onClick={() => removeItem(file)} style={{ cursor: 'pointer' }}>
            {file.path} - {file.size / 1000000} MB &times;
        </li>
    ));

    return (
        <div>
            <div {...getRootProps()} style={isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles}>
                <input {...getInputProps()} />
                <BackupOutlined />
                <Typography variant="h6" gutterBottom>
                    Kattints ide a fájlok kiválasztásához!
                </Typography>
            </div>
            <ul>{files}</ul>
        </div>
    );
};

export default FilesDropzoneWidget;
