import React, { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

// eslint-disable-next-line react/display-name
const CustomPage = forwardRef(({ title, children, ...rest }: any, ref) => {
    const styles = useStyles();

    return (
        <div className={styles.root} ref={ref} {...rest}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </div>
    );
});

export default CustomPage;
