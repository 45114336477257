import React, { useState } from 'react';
import { Box, CircularProgress, DialogContentText, Grid, Typography } from '@mui/material';
import SimpleImageCard from 'components/SimpleImageCard';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import ImageUploadWidget from 'components/ImagesUploadWidget';
import agent from 'api/agent';

const CreateNewImagesGrid: React.FC<{
    imagesToUpload: ImageUploadResult[];
    setImagesToUpload: (imageUploadResults: ImageUploadResult[]) => void;
    setIsUploadImageLoading: (isLoading: boolean) => void;
    isUploadImageLoading: boolean;
}> = ({ imagesToUpload, setImagesToUpload, isUploadImageLoading, setIsUploadImageLoading }) => {
    const [uploadLoadingMessage, setUploadLoadingMessage] = useState('');

    const deleteImage = (indexImageUrl: string) => {
        agent.Image.deleteImage(indexImageUrl)
            .then(() => {
                const filteredImages = imagesToUpload.filter((image) => image.imageUrl != indexImageUrl);
                setImagesToUpload(filteredImages);
            })
            .finally(() => {
                setIsUploadImageLoading(false);
            });
    };
    return (
        <>
            {isUploadImageLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress color="inherit" />
                    {uploadLoadingMessage && (
                        <Typography component="div">
                            <Box textAlign="center" m={1}>
                                {uploadLoadingMessage}
                            </Box>
                        </Typography>
                    )}
                </Box>
            ) : (
                <div>
                    <Box mt={2}>
                        <DialogContentText>Képek feltöltése:</DialogContentText>
                        <ImageUploadWidget
                            setImageUrlToUpload={setImagesToUpload}
                            setIsLoading={setIsUploadImageLoading}
                            setLoadingMessage={setUploadLoadingMessage}
                        />
                    </Box>

                    {imagesToUpload.length > 0 && (
                        <>
                            <DialogContentText>Új Képek:</DialogContentText>
                            <Grid container spacing={1}>
                                {imagesToUpload.map((image) => (
                                    <Grid item xs={12} sm={6} key={image.imageUrl}>
                                        <SimpleImageCard
                                            imageUrl={image.imageUrl}
                                            buttons={[
                                                {
                                                    onButtonAction: () => {
                                                        setUploadLoadingMessage('Kép törlése...');
                                                        setIsUploadImageLoading(true);
                                                        deleteImage(image.imageUrl);
                                                    },
                                                    buttonText: 'Törlés',
                                                    color: 'error',
                                                },
                                            ]}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default CreateNewImagesGrid;
