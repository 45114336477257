/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { showErrorToast } from 'utils/customToaster';
import { useNavigate } from 'react-router-dom';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { Paginated } from 'models/common/pagination';
import { IBusinessEvent } from 'models/businessEvent/businessEvent';
import { OrderByName } from 'models/common/orderByName';
import { INominee } from 'models/nominee/nominee';
import { IAttendee } from 'models/attendee/attendee';
import { IReference } from 'models/reference/reference';
import { ILessDetailedBusinessEvent } from 'models/businessEvent/lessDetailedBusinessEvent';
import { ILessDetailedNominee } from 'models/nominee/lessDetailedNominee';
import { IBusinessEventByName } from 'models/businessEvent/businessEventByName';
import { ILessDetailedAttendee } from 'models/attendee/lessDetailedAttendee';
import { IUpdateableBusinessEvent } from 'models/businessEvent/updateableBusinessEvent';
import { UpdateBusinessEventAttachment } from 'models/businessEvent/updateAttachment';
import { INewBusinessEvent } from 'models/businessEvent/newBusinessEvent';
import { FileUploadResult } from 'models/common/fileUploadResult';
import { IUpdateableNominee } from 'models/nominee/updateableNominee';
import { INomineeImageUpdate } from 'models/nominee/nomineeImageUpdate';
import { INewNominee } from 'models/nominee/newNominee';
import { INewReference } from 'models/reference/newReference';
import { IUpdateableReference } from 'models/reference/updateableReference';
import { IUpdateReferenceThumbnailImage } from 'models/reference/updateThumbnailImage';
import { IUpdateImages } from 'models/common/updateImageById';
import { IUpdateSponsorsById } from 'models/businessEvent/updateSponsorsById';
import { acquireAccessToken } from 'utils/tokenService';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (typeof config !== 'undefined') {
            const accessToken = await acquireAccessToken();

            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }

            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(undefined, (error) => {
    if (!error || !error.response) {
        return;
    }

    if (error.message === 'Network Error' && !error.response) {
        showErrorToast('Nem érhető el a szerver!');
    }
    const { status, data, config, headers } = error.response;
    if (status === 400) {
        showErrorToast(data.message);
    } else if (status === 404) {
        showErrorToast('Kiválasztott/keresett adat nem található!');
    } else if (status === 406) {
        showErrorToast(data.message);
    } else if (status === 401) {
        showErrorToast('Nincs jogod a művelethez!');
    } else if (status === 500) {
        showErrorToast('Hiba történt a művelet során! Kérem ellenőrizze le, hogy minden mezőt kitöltött!');
    } else if (
        status === 401 &&
        headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"'
    ) {
        window.localStorage.removeItem('jwt');
        const navigate = useNavigate();
        navigate('/');
        showErrorToast('Lejárt a megengedett időkorlát, lépj be újra!');
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response?.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    patch: (url: string, body?: {}) => axios.patch(url, body).then(responseBody),
    postFile: (url: string, file: Blob) => {
        const formData = new FormData();
        formData.append('file', file);
        return axios
            .post(url, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
            })
            .then(responseBody);
    },
    postFiles: (url: string, files: Blob[]) => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        return axios
            .post(url, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
            })
            .then(responseBody);
    },
};

const ImageRequests = {
    uploadImages: (images: Blob[]): Promise<ImageUploadResult[]> => requests.postFiles(`/Image/AddImages/`, images),
    uploadImage: (image: Blob): Promise<ImageUploadResult> => requests.postFile(`/Image/AddImage/`, image),
    deleteImage: (imageUrl: string): Promise<any> => requests.post(`/Image/DeleteImage/`, { imageUrl }),
    deleteImages: (images: string[]): Promise<any> => requests.post(`/Image/DeleteImages/`, { images }),
};

const FileRequests = {
    uploadFile: (file: Blob): Promise<FileUploadResult> => requests.postFile(`/File/AddFile/`, file),
    deleteFile: (fileUrl: string): Promise<any> => requests.post(`/File/RemoveFile`, { fileUrl }),
};

const NomineeRequests = {
    create: (nominee: INewNominee): Promise<any> => requests.post('/Nominee/CreateNominee', nominee),
    delete: (nomineeId: String): Promise<any> => requests.del(`/Nominee/DeleteNominee/${nomineeId}`),
    update: (nominee: IUpdateableNominee): Promise<string> => requests.put('/Nominee/UpdateNominee', nominee),
    updateImage: (nomineeImage: INomineeImageUpdate): Promise<string> =>
        requests.put('/Nominee/UpdateNomineeImage', nomineeImage),
    getById: (nomineeId: string): Promise<INominee> => requests.get(`/Nominee/GetNomineeById/${nomineeId}`),
    getFilteredPage: (
        pageNumber: number,
        pageSize: number,
        businessEventId?: string,
        nomineeName?: string,
        orderByName?: OrderByName,
    ): Promise<Paginated<ILessDetailedNominee>> =>
        requests.post(`/Nominee/GetFilteredNominees`, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            businessEventId: businessEventId,
            nomineeName: nomineeName,
            orderByName: orderByName,
        }),
};

const AttendeeRequests = {
    create: (attendee: IAttendee): Promise<any> => requests.post('/Attendee/CreateAttendee', attendee),
    delete: (attendeeId: String): Promise<any> => requests.del(`/Attendee/DeleteAttendee/${attendeeId}`),
    update: (attendee: IAttendee): Promise<string> => requests.put('/Attendee/UpdateAttendee', attendee),
    getById: (id: string): Promise<IAttendee> => requests.get(`/Attendee/GetAttendeeById/${id}`),
    getFilteredPage: (
        pageNumber: number,
        pageSize: number,
        businessEventId?: string,
        name?: string,
    ): Promise<Paginated<ILessDetailedAttendee>> =>
        requests.post(`/Attendee/GetFilteredAttendees`, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            businessEventId: businessEventId,
            name: name,
        }),
};

const ReferenceRequests = {
    create: (newReference: INewReference): Promise<any> => requests.post('/Reference/CreateReference', newReference),
    delete: (id: String): Promise<any> => requests.del(`/Reference/DeleteReference/${id}`),
    update: (reference: IUpdateableReference): Promise<string> => requests.put('/Reference/UpdateReference', reference),
    updateThumbnailImage: (reference: IUpdateReferenceThumbnailImage): Promise<string> =>
        requests.put('/Reference/UpdateReferenceThumbnail', reference),
    updateImages: (reference: IUpdateImages): Promise<string> =>
        requests.put('/Reference/UpdateReferenceImages', reference),
    getById: (id: string): Promise<IReference> => requests.get(`/Reference/GetReferenceById/${id}`),
    getFilteredPage: (pageNumber: number, pageSize: number, name?: string): Promise<Paginated<IReference>> =>
        requests.post(`/Reference/GetFilteredReferences`, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            name: name,
        }),
};

const BusinessEventRequests = {
    create: (businessEvent: INewBusinessEvent): Promise<any> =>
        requests.post('/BusinessEvent/CreateBusinessEvent', businessEvent),
    delete: (businessEventId: string): Promise<any> =>
        requests.del(`/BusinessEvent/DeletebusinessEvent/${businessEventId}`),
    update: (businessEvent: IUpdateableBusinessEvent): Promise<string> =>
        requests.put('/BusinessEvent/UpdateBusinessEvent', businessEvent),
    disableNomineesById: (id: string): Promise<string> => requests.put(`/BusinessEvent/DisableNomineesById/${id}`, {}),
    enableNomineesById: (id: string): Promise<string> => requests.put(`/BusinessEvent/EnableNomineesById/${id}`, {}),
    updateAttachmentById: (updateableBusinessEventAttachment: UpdateBusinessEventAttachment): Promise<string> =>
        requests.put('/BusinessEvent/UpdateAttachmentById', updateableBusinessEventAttachment),
    updateImagesById: (updateBusinessImagesById: IUpdateImages): Promise<string> =>
        requests.put('/BusinessEvent/UpdateImagesById', updateBusinessImagesById),
    updateSponsorsById: (updateSponsorsById: IUpdateSponsorsById): Promise<string> =>
        requests.put('/BusinessEvent/UpdateSponsorsById', updateSponsorsById),
    getById: (businessEventId: string): Promise<IBusinessEvent> =>
        requests.get(`/BusinessEvent/GetBusinessEventById/${businessEventId}`),
    getAll: (): Promise<IBusinessEventByName[]> => requests.get(`/BusinessEvent/GetAllBusinessEvents`),
    getFilteredPage: (
        pageNumber: number,
        pageSize: number,
        fromDate?: Date,
        toDate?: Date,
        businessEventName?: string,
        orderByName?: OrderByName,
    ): Promise<Paginated<ILessDetailedBusinessEvent>> =>
        requests.post(`/BusinessEvent/GetFilteredBusinessEvents`, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            businessEventName: businessEventName,
            fromDate: fromDate,
            toDate: toDate,
            orderByName: orderByName,
        }),
};

export default {
    File: FileRequests,
    Image: ImageRequests,
    Nominee: NomineeRequests,
    Reference: ReferenceRequests,
    Attendee: AttendeeRequests,
    BusinessEvent: BusinessEventRequests,
};
