import React, { useContext } from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { RootStoreContext } from 'mobx/rootStore';

const Header: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const { selectedNominee } = useContext(RootStoreContext).nomineeStore;

    const getNomineeName = () => {
        if (isLoading || !selectedNominee) {
            return '';
        }

        return ` - ${selectedNominee.name}`;
    };

    return (
        <Grid container spacing={3}>
            <Grid item>
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    <Link to="/">
                        <span style={{ color: 'black' }}>Főoldal</span>
                    </Link>
                    <Link to="/jeloltek">
                        <span style={{ color: 'black' }}>Jelöltek kezelése</span>
                    </Link>
                    <Typography variant="body1" color="textPrimary">
                        {'Jelölt szerkesztés'}
                    </Typography>
                </Breadcrumbs>
                <Typography variant="h3" color="textPrimary">
                    {`Jelölt szerkesztése ${getNomineeName()}`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
