import { action, computed, makeObservable, observable } from 'mobx';
import { IReference } from 'models/reference/reference';
import { RootStore } from './rootStore';
import agent from 'api/agent';

export default class ReferenceStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable selectedReference: IReference | null = null;
    @observable filteredReferencess: Map<string, IReference> = new Map();

    @computed get getFilteredReferences(): IReference[] {
        return Array.from(this.filteredReferencess.values());
    }

    @action loadSelectedReference = async (id: string): Promise<void> => {
        this.selectedReference = await agent.Reference.getById(id);
    };
}
